<template>
  <div>
    <section class="hero hero-product">
      <div class="container">
        <div v-if="product" class="__content">
          <h1 class="hero-title">
            {{ product.name }}
          </h1>
          <div class="hero-meta card-meta display--flex">
            <div v-if="product.label_people" class="__item display--flex">
              <img src="@/assets/images/icons/users-icon.svg" alt="" />
              {{ product.label_people }}
            </div>
            <div v-if="product.label_duration" class="__item display--flex">
              <img src="@/assets/images/icons/clock-icon.svg" alt="" />
              {{ product.label_duration }}
            </div>
            <div v-if="
              product.label_description &&
              (!product.label_people || !product.label_duration)
            " class="__item display--flex">
              <i class="fal fa-comments"></i>
              {{ product.label_description }}
            </div>
          </div>
          <div v-if="!product.price_from && !type" class="hero-price display--flex">
            <div v-if="product.old_price" class="__old">
              {{ product.old_price }} ₽
            </div>
            <div class="__current">{{ product.price }} ₽</div>
          </div>
          <div v-else class="hero-price display--flex">
            <div class="__current">
              От {{ product.price_from ? product.price_from : product.price }} ₽
            </div>
          </div>
          <div class="hero-action btn-action display--flex">
            <button v-if="!type && getRight('buyProduct')" @click="addToCart(product)" type="button"
              class="btn btn-accent">
              Купить этот продукт
            </button>
            <button v-else-if="!type" disabled
              title="Покупка доступна только со счета компании. Обратитесь к вашему руководству." type="button"
              class="btn btn-accent">
              Купить этот продукт
            </button>
            <button v-else @click="toComplexProducts" type="button" class="btn btn-accent">
              Выбрать пакет
            </button>
            <button @click="openConsultModal = true" type="button" class="btn btn-o">
              Проконсультироваться
            </button>
          </div>
          <p class="alert" v-if="!getRight('buyProduct')">Покупка доступна только со счета компании. Обратитесь к вашему руководству.</p>
        </div>
        <img v-if="product && product.middle_image" class="hero-product--image" :src="product.middle_image"
          :alt="product.name" @error="
            $event.target.src = require('../../assets/images/technical/not_a_img.png')
          " />
        <img v-else class="hero-product--image _loading" src="../../assets/images/technical/not_a_img.png" alt="" />
      </div>
    </section>

    <section v-if="product" class="index-section s-product--about">
      <div class="container">
        <div class="s-product--about-text">
          <p v-html="product.description"></p>
        </div>

        <div v-if="product.text_1.length" class="s-product--about-sm--grid">
          <div class="__inner display--grid grid-3">
            <div class="__item">
              <img :src="product.image_1" />
              <p v-html="product.text_1"></p>
            </div>
            <div class="__item">
              <img :src="product.image_2" />
              <p v-html="product.text_2"></p>
            </div>
            <div class="__item">
              <img :src="product.image_3" />
              <p v-html="product.text_3"></p>
            </div>
          </div>
        </div>
        <img v-if="product.product_video" class="hidden"
          :src="`https://img.youtube.com/vi/${product.product_video}/maxresdefault.jpg`" @error="videoPreview = false" />
        <div v-if="product.product_video" class="s-product--about-video display--flex bg-light" ref="videoPreview" :style="
          videoPreview
            ? `background-image: url('https://img.youtube.com/vi/${product.product_video}/maxresdefault.jpg');`
            : `background-image: url('https://img.youtube.com/vi/${product.product_video}/sddefault.jpg);`
        ">
          <button type="button" @click="openVideoModal = true" class="btn btn-accent btn-ico">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="12" fill="white" />
              <path d="M16 12.5L10 17L10 8L16 12.5Z" fill="#01AF08" />
            </svg>
            Смотреть видео
          </button>
        </div>
        <div v-if="product.product_image && !product.product_video">
          <img class="product-image" :src="product.product_image" alt="" />
        </div>
        <div v-if="product.text_4.length" class="s-product--about-lg--grid display--grid grid-2">
          <div class="__item">
            <p v-html="product.text_4"></p>
          </div>
          <div class="__item">
            <p v-html="product.text_5"></p>
          </div>
        </div>

        <video-ei :videoUrl="product.product_video" @closeVideoModal="openVideoModal = false"
          v-if="openVideoModal"></video-ei>
      </div>
    </section>

    <section v-if="product && product.products">
      <div class="container">
        <div class="complex-section">
          <p class="complex-section__title part">
            Комплексные решения «{{ product.name }}»
          </p>
          <p class="complex-section__subtitle">
            Выберите предложение, которое подходит именно Вам
          </p>
          <div class="complex-cards display--flex">
            <div v-for="(subproduct, index) of product.products.data" :key="subproduct.id" class="complex-card" :class="{
              'complex-card__recommended':
                product.products.data.length > 2 && index == 1,
            }">
              <div v-if="product.products.data.length > 2 && index == 1" class="complex-card__recommendation">
                Рекомендуем
              </div>
              <div class="complex-card__title">{{ subproduct.name }}</div>
              <div class="card-meta display--flex">
                <div v-if="subproduct.label_people" class="__item display--flex">
                  <img src="@/assets/images/icons/users-icon.svg" alt="" />
                  {{ subproduct.label_people }}
                </div>
                <div v-if="subproduct.label_duration" class="__item display--flex">
                  <img src="@/assets/images/icons/clock-icon.svg" alt="" />
                  {{ subproduct.label_duration }}
                </div>
                <div v-if="
                  subproduct.label_description &&
                  (!subproduct.label_people || !subproduct.label_duration)
                " class="__item display--flex">
                  <i class="fal fa-comments"></i>
                  {{ subproduct.label_description }}
                </div>
              </div>

              <div class="complex-card__description">
                {{ subproduct.short_description }}
              </div>
              <div class="complex-card__price display--flex">
                <div v-if="subproduct.old_price" class="complex-card__old">
                  {{ subproduct.old_price }} ₽
                </div>
                <div class="complex-card__current">
                  {{ subproduct.price }} ₽
                </div>
              </div>
              <button v-if="getRight('buyProduct')" @click="addToCart(subproduct)" type="button" class="btn btn-o btn-sm">
                Купить пакет
              </button>
              <button v-else disabled title="Покупка доступна только со счета компании. Обратитесь к вашему руководству."
                type="button" class="btn btn-accent">
                Купить пакет
              </button>
            </div>
          </div>
          <p class="alert" v-if="!getRight('buyProduct')">Покупка доступна только со счета компании. Обратитесь к вашему руководству.</p>
          <button @click="openConsultModal = true" type="button" class="btn btn-o">
            Проконсультироваться
          </button>
        </div>
      </div>
    </section>

    <section class="bottom-btns-section">
      <div class="container">
        <div class="hero-action btn-action display--flex">
          <button v-if="!type && getRight('buyProduct')" @click="addToCart(product)" type="button" class="btn btn-accent">
            Купить
          </button>
          <button v-else-if="!type" disabled
            title="Покупка доступна только со счета компании. Обратитесь к вашему руководству." type="button"
            class="btn btn-accent">
            Купить
          </button>
          <button @click="openConsultModal = true" type="button" class="btn btn-o">
            Проконсультироваться
          </button>
        </div>
        <p class="alert" v-if="!getRight('buyProduct')">Покупка доступна только со счета компании. Обратитесь к вашему руководству.</p>
      </div>
    </section>

    <product-slider v-if="product && product.relates.data.length" title="Возможно вас заинтересует"
      :products="product.relates.data"></product-slider>
    <product-slider v-else title="Возможно вас заинтересует"></product-slider>
  </div>
  <pop-up-form title="Получить консультацию по продукту" postField commentField :productId="product.id"
    type="ConsultProduct" v-if="openConsultModal" @closeModal="openConsultModal = false" />
</template>

<script>
import {
  computed,
  onBeforeMount,
  onMounted,
  ref,
  watch,
} from "@vue/runtime-core";
import { useStore } from "vuex";
import ProductSlider from "../../components/sliders/product-slider/ProductSlider.vue";
import { useRoute, useRouter } from "vue-router";
import useCheckRights from "./../../mixins/useCheckRights";
import PopUpForm from "../../components/pop-up-form/PopUpForm.vue";
import VideoEi from "../../components/video-ei/VideoEi.vue";
import { useHead } from "@vueuse/head";
export default {
  name: "product-page",
  components: { ProductSlider, PopUpForm, VideoEi },
  inject: ["backUrl"],
  setup() {
    const store = useStore(),
      route = useRoute(),
      router = useRouter(),
      { getRight } = useCheckRights();
    const popularProducts = computed(
      () => store.state.products.popularProducts
    ),
      product = computed(() => store.state.products.product);

    useHead({
      title: computed(() => (product.value ? product.value.name : "")),
      meta: [
        {
          property: "og:description",
          content: computed(() =>
            product.value ? product.value.short_description : ""
          ),
        },
      ],
    });

    let openConsultModal = ref(false),
      openVideoModal = ref(false),
      type = ref(0),
      videoPreview = ref(true);

    let getProduct = () => {
      if (route.path.startsWith("/product")) {
        type.value = 0;
        store.dispatch("products/getProductBySlug", route.params.slug).then(() => {
          if (!product.value) {
            router.push("/404");
          }
        });
      } else {
        type.value = 1;
        store
          .dispatch("products/getComplexProductBySlug", route.params.slug)
          .then(() => {
            if (!product.value) {
              router.push("/404");
            }
          });
      }
    };

    let addToCart = (product) => {
      let newCartProduct = {
        id: product.id,
        product_count: 1,
        isChecked: true,
        slug: product.slug
      };
      const cartProducts = JSON.parse(localStorage.getItem("cartProducts"));
      if (cartProducts) {
        let index = cartProducts.findIndex(
          (item) => item.id === newCartProduct.id
        );
        if (index >= 0) {
          cartProducts[index].product_count =
            cartProducts[index].product_count + 1;
          cartProducts[index].totalPrice =
            cartProducts[index].totalPrice + cartProducts[index].price;
        } else {
          cartProducts.push(newCartProduct);
        }

        localStorage.setItem("cartProducts", JSON.stringify(cartProducts));
      } else {
        localStorage.setItem("cartProducts", JSON.stringify([newCartProduct]));
      }
      router.push("/cart");
    };
    let toComplexProducts = () => {
      document.querySelector(".complex-section").scrollIntoView({
        behavior: "smooth",
      });
    };

    onBeforeMount(() => {
      store.commit("products/setProduct", null);
    });

    onMounted(() => {
      getProduct();
      window.scrollTo({ top: 0, behavior: "smooth" });
    });

    watch(
      () => route.params.slug,
      () => {
        if (
          route.path.startsWith("/product") &&
          !route.path.startsWith("/products")
        ) {
          getProduct();
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }
    );

    return {
      popularProducts,
      product,
      getProduct,
      addToCart,
      openConsultModal,
      openVideoModal,
      toComplexProducts,
      type,
      videoPreview,
      getRight
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/s-product";

.hero-product .__content {
  max-width: 60%;
}

.s-team--video .s-product--about-video {
  height: 650px;
}

.hero-product .hero-title,
.hero-diagnostic .hero-title {
  margin-bottom: 12px;
  font-size: 46px;
  line-height: 51px;
  letter-spacing: -0.02em;
}

.hero-product .hero-meta {
  margin-bottom: 12px;
}

.hero-product--image._loading {
  top: -120px;
}

.product-image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: 500px;
  margin: 0 0 35px 0;
  border-radius: 10px;
  -o-object-fit: cover;
  object-fit: cover;

  @media (max-width: 767px) {
    height: 240px;
  }
}

.alert {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}

.hero-product--image {
  position: absolute;
  top: -10px;
  right: 0;
  width: 350px;
  height: auto;
}

.card-meta {
  align-items: center;
  justify-content: flex-start;
  color: var(--color-light-2);
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}

.card-meta .__item {
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
}

.card-meta .__item:not(:first-child) {
  margin-left: 8px;

  @media (max-width: 931px) {
    margin-left: 0;
  }
}

.card-meta img {
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.card-meta i {
  margin-right: 4px;
  padding: 6px 8px;
}

.hero-price .__old {
  position: relative;
  color: var(--color-light-2);
}

.hero-price .__old::before {
  position: absolute;
  display: block;
  content: "";
  top: 50%;
  right: -2px;
  left: -2px;
  height: 2px;
  background: var(--color-light-2);
}

.bottom-btns-section {
  padding-bottom: 80px;
}

.s-product--about {
  padding-bottom: 40px;
}

.complex-section {
  background-color: var(--color-light);
  text-align: center;
  padding: 35px;
  margin-bottom: 60px;

  // .complex-section__title

  &__title {
    display: block;
    font-size: 46px;
    padding: 0 35px 0 35px;
    margin: 0 auto 12px auto;
    max-width: 60%;
    line-height: 51px;
  }

  // .complex-section__subtitle

  &__subtitle {
    font-size: 24px;
  }

  @media (max-width: 931px) {
    padding: 20px;

    &__title {
      max-width: 100%;
      font-size: 28px;
      line-height: 120%;
    }

    &__subtitle {
      font-size: 18px;
    }
  }
}

.complex-cards {
  margin-bottom: 40px;
  justify-content: center;

  @media (max-width: 931px) {
    flex-direction: column;
    margin-bottom: 15px;
  }
}

.complex-card {
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  margin: 20px 0;
  font-size: 18px;
  line-height: 21px;
  flex: 0 1 30%;

  &:nth-child(2) {
    margin: 20px 30px;
  }

  &__recommended {
    flex: 0 1 34%;
    padding: 30px 20px;
    margin: 0 30px 0 30px !important;

    @media (max-width: 931px) {
      margin: 0 !important;
      padding: 20px;
    }
  }

  &__recommendation {
    position: absolute;
    border-radius: 30px;
    background-color: var(--accent);
    color: white;
    padding: 2px 8px 4px;
    top: -12px;
    right: 31px;
    font-size: 16px;
  }

  &__title {
    font-size: 28px;
    line-height: 33px;
    margin-bottom: 15px;
  }

  .card-meta {
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color);

    @media (max-width: 931px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  // .complex-card__description

  &__description {
    flex-grow: 1;
    border-bottom: 1px solid var(--border-color);
    width: 100%;
    padding: 20px 0;
    text-align: left;
    white-space: pre-line;
    line-height: 1.2;

    li {
      padding: 0;
      list-style-type: none;
    }

    ul {
      padding: 0;
    }
  }

  &__price {
    margin: 20px 0;
  }

  &__old {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-light-2);
    text-decoration: line-through;
  }

  &__current {
    font-size: 24px;
    line-height: 28px;
    margin-left: 14px;
    font-weight: 400;
  }
}

@media (max-width: 1100px) {
  .hero-product .__content {
    max-width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  .hero-product--image {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hero-product .hero-title {
    font-size: 28px;
    line-height: 34px;
    text-align: left;
  }

  .hero-product--image {
    position: relative;
    top: 0;
    right: auto;
    width: 100%;
    max-width: 350px;
    margin-top: 24px;
    margin-bottom: -70px;
  }

  .s-product--about-video {
    height: 220px !important;
    margin-bottom: 35px;
  }

  .hero-product .btn {
    justify-content: center;
  }
}
</style>